// Buttons
//----------------------------------------
$btn-padding:                       $spacing-4 $spacing-6;
$btn-large-padding:                 ($spacing-5 + $spacing-2) $spacing-7; //20px 32px
$btn-small-padding:                 $spacing-3 $spacing-5;
$btn-border-radius:                 $spacing-3;
$btn-opacity:                       0.16;

$btn-skin-1-color:                 $grey-800;
$btn-skin-1-bg:                    transparent;
$btn-skin-1-hover-color:           $color-white;
$btn-skin-1-hover-bg:              $btn-skin-1-color;

$btn-skin-2-color:                 $grey-200;
$btn-skin-2-bg:                    $grey-800;
$btn-skin-2-hover-color:           $grey-700;
$btn-skin-2-hover-bg:              transparent;

$btn-skin-3-color:                 $color-white;
$btn-skin-3-bg:                    transparent;
$btn-skin-3-hover-color:           $grey-700;
$btn-skin-3-hover-bg:              $btn-skin-3-color;

$btn-icon-padding:                 $spacing-5 $spacing-8 $spacing-5 $spacing-7;
$btn-icon-size:                    $spacing-6;
$btn-icon-color:                   $color-white;

// base button abstract class. All the btn-skin-x classes needs to extend this abstract class
%base-button-styles {
    display: inline-block;
    padding: $btn-padding;
    line-height: 1;
    border-radius: $btn-border-radius;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    @include display-medium;
    @include shadow-level(3);
    text-decoration: none;

    &:not(.btn-disabled) {
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    @media (min-width: $screen-sm) {
        // @include display-extra-small;
    }
}

@mixin btn-large {
    @include display-large;
    display: inline-block;
    padding: $btn-large-padding;

    &.btn-skin-2 {
        color: $btn-skin-2-color;

        &:hover,
        &:focus {
            color: $btn-skin-2-hover-color;
            background-color: $btn-skin-2-hover-bg;
        }
    }
}

.btn-large {
    @include btn-large;
}

@mixin btn-small {
    @include display-small;
    display: inline-block;
    padding: $btn-small-padding;
}

.btn-small {
    @include btn-small;
}

.btn-disabled,
input[type="submit"].btn-disabled {
    cursor: default;
    opacity: $btn-opacity;

    &:hover {
        cursor: default;
        text-decoration: none;
    }
}

input[type="submit"].btn-primary,
input[type="submit"].btn-default {
    border: none;
    cursor: pointer;
}

/*
 * Button Themes
 */
//Primary
@mixin btn-skin-1 {
    @extend %base-button-styles;
    color: $btn-skin-1-color;
    background-color: $btn-skin-1-bg;
    border: 1px solid $btn-skin-1-color;

    &:not(.btn-disabled) {
        &:hover,
        &:focus {
            color: $btn-skin-1-hover-color;
            background-color: $btn-skin-1-hover-bg;
        }
    }
}

.btn-skin-1 {
    @include btn-skin-1;
}

//Secondary
.btn-skin-2 {
    @extend %base-button-styles;
    color: $btn-skin-2-color;
    background-color: $btn-skin-2-bg;
    border: 1px solid $btn-skin-2-bg;
    @include shadow-level(4, 1);

    &:not(.btn-disabled) {
        &:hover,
        &:focus {
            color: $btn-skin-2-hover-color;
            background-color: $btn-skin-2-hover-bg;
        }
    }
}

@mixin btn-skin-3 {
    @extend %base-button-styles;
    color: $btn-skin-3-color;
    background-color: $btn-skin-3-bg;
    border: 1px solid $btn-skin-3-color;

    &:not(.btn-disabled) {
        &:hover,
        &:focus {
            color: $btn-skin-3-hover-color;
            background-color: $btn-skin-3-hover-bg;
        }
    }
}

.btn-skin-3 {
    @include btn-skin-3;
}

/*
 * Buttons with Icons
 */
.btn-icon {
    position: relative;
    padding: $btn-icon-padding;

    svg {
        position: absolute;
        top: calc(50% - (#{$btn-icon-size} / 2));
        right: $spacing-3;
        width: $btn-icon-size;
        height: $btn-icon-size;
        fill: $btn-icon-color;
    }
}
