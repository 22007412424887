// Tables
$table-heading-bg:                  $color-primary-2;
$table-heading-color:               $color-white;

$table-row-bg:                      $color-shade-1;
$table-row-alt-bg:                  $color-white;
$table-row-hover-bg:                $color-shade-2;

$table-cell-padding:                $spacing-5 $spacing-6;
$table-cell-color:                  $color-black;


table {
    width: 100%;
    border-collapse: collapse;

    th {
        padding: $table-cell-padding;
        background-color: $table-heading-bg;
        text-align: left;
        color: $table-heading-color;
    }

    tr {
        background-color: $table-row-bg;

        &:nth-child(even) {
            background-color: $table-row-alt-bg;
        }

        &:hover {
            background-color: $table-row-hover-bg;
        }
    }

    td {
        padding: $table-cell-padding;
        color: $table-cell-color;
    }
}
