// Intrinsic Placeholder Ratios
// =======================================================================
// Polyfill for IE - https://github.com/bfred-it/object-fit-images/
// with PostCSS plugin - https://github.com/ronik-design/postcss-object-fit-images


// http://daverupert.com/2015/12/intrinsic-placeholders-with-picture
$image-placeholder-bg:        $color-shade-2;

// aspect ratio calculator function based on
// https://css-tricks.com/snippets/sass/maintain-aspect-ratio-mixin/
@function aspect-ratio($width, $height) {
    @return ($height / $width) * 100%;
}


@mixin object-align($alignment) {
    /* Because of the polyfill we use to make object-fit work with IE11, we have to set object-fit and object-position at the same time for every instance */
    object-fit: none; // Generally we don't want to force uploaded images to fit. That's what the alignment classes are for.
    object-position: $alignment;
}

@mixin image-ratio($ratio: '3x2') {
    position: relative;
    height: 0;
    width: 100%;
    background-color: $image-placeholder-bg;

    img {
        position: absolute;
        width: 100%;
        height: 100%;
        @include object-align(center center);
    }

    iframe,
    object,
    embed {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    @if ($ratio == '4x3') {
        padding-bottom: aspect-ratio(4, 3);
    }

    @else if ($ratio == '16x9') {
        padding-bottom: aspect-ratio(16, 9);
    }

    @else if ($ratio == '21x9') {
        padding-bottom: aspect-ratio(21, 9);
    }

    @else if ($ratio == '1x1') {
        padding-bottom: 100%;
    }

    @else {
        padding-bottom: aspect-ratio(3, 2);
    }
}

.ratio-3-2 {
    @include image-ratio();
}

.ratio-4-3 {
    @include image-ratio('4x3');
}

.ratio-16-9 {
    @include image-ratio('16x9');
}

.ratio-21-9 {
    @include image-ratio('21x9');
}

.ratio-1-1 {
    @include image-ratio('1x1');
}


@mixin align-top-left {
    @include object-align(top left);
}

.align-top-left img {
    @include align-top-left;
}

@mixin align-top-center {
    @include object-align(top center);
}

.align-top-center img {
    @include align-top-center;
}

@mixin align-top-right {
    @include object-align(top right);
}

.align-top-right img {
    @include align-top-right;
}

@mixin align-center-left {
    @include object-align(center left);
}

.align-center-left img {
    @include align-center-left;
}

@mixin align-center-center {
    @include object-align(center center);
}

.align-center-center img {
    @include align-center-center;
}

@mixin align-center-right {
    @include object-align(center right);
}

.align-center-right img {
    @include align-center-right;
}

@mixin align-bottom-left {
    @include object-align(bottom left);
}

.align-bottom-left img {
    @include align-bottom-left;
}

@mixin align-bottom-center {
    @include object-align(bottom center);
}

.align-bottom-center img {
    @include align-bottom-center;
}

@mixin align-bottom-right {
    @include object-align(bottom right);
}

.align-bottom-right img {
    @include align-bottom-right;
}
