// BODY Typography Styles
// =======================================================================
$base-body-colour-light:     $color-white;

$body-base-map: (
    null: ($text-base, $leading-normal),
    sm: ($text-lg, $leading-normal)
);

$body-xl-map: (
    null: ($text-xl, $leading-normal),
    sm: ($text-2xl, $leading-normal)
);

$body-lg-map: (
    null: ($text-lg, $leading-normal),
    sm: ($text-xl, $leading-normal)
);

$body-sm-map: (
    null: ($text-sm, $leading-loose),
    sm: ($text-base, $leading-loose)
);

$body-xs-map: (
    null: ($text-xs, $leading-loose)
);


@mixin body-base {
    @include font-size($body-base-map);
}

.body-base {
    @include body-base;
}

p {
    @include body-base;
    margin-bottom: $spacing-3;
}

@mixin body-xl {
    @include font-size($body-xl-map);
}

.body-xl {
    @include body-xl;
}

@mixin body-lg {
    @include font-size($body-lg-map);
}

.body-lg {
    @include body-lg;
}

@mixin body-sm {
    @include font-size($body-sm-map);
}

.body-sm {
    @include body-sm;
}

@mixin body-xs {
    @include font-size($body-xs-map);
}

.body-xs {
    @include body-xs;
}

// Blockquote
// =======================================================================
$blockquote-map: (
    null: ($text-xl, $leading-tight),
    sm: ($text-3xl, $leading-tight)
);


@mixin body-blockquote {
    @include font-size($blockquote-map);
}

blockquote,
q {
    @include body-blockquote;
    display: block;
}

q {
    &::before,
    &::after {
        font-style: italic;
    }

    &::before {
        padding-right: $spacing-2;
    }
}

// Links
// =======================================================================
@mixin fancy-underline($color: $grey-800) {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: -$spacing-2;
        left: 0;
        background-color: $color;
        visibility: hidden;
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
        -webkit-transition: all 0.2s ease-in-out 0s;
        transition: all 0.2s ease-in-out 0s;
    }

    &:hover,
    &:focus {
        text-decoration: none;

        &::before {
            visibility: visible;
            -webkit-transform: scaleX(1);
            transform: scaleX(1);
        }
    }
}

a {
    cursor: pointer;
    text-decoration: none;
    font-weight: $font-semibold;

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
