.s-multicolumn-content {
    @include bg-theme-2;
    @include base-section-spacing-default('padding');

    .multicolumn-content-container {
        > p {
            @include body-lg;
            margin-top: $spacing-6;
        }

        .tile-container {
            display: -ms-grid;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: $spacing-6;
            margin-top: $spacing-7;
            @include ie-grid(1fr, true, 3, $spacing-6);
        }
    }
}

@media (min-width: $screen-sm) {
    .s-multicolumn-content {
        .row {
            @include grid-center;
        }

        .multicolumn-content-container {
            @include col(11);
        }
    }
}

@media (min-width: $screen-md) {
    .s-multicolumn-content {
        &.col-two .multicolumn-content-container {
            .tile-container {
                grid-template-columns: 1fr 1fr;
                @include ie-grid(1fr 1fr, true, 2, $spacing-7);
            }
        }

        &.col-three .multicolumn-content-container {
            .tile-container {
                grid-template-columns: 1fr 1fr 1fr;
                @include ie-grid(1fr 1fr 1fr, true, 1, $spacing-7);
            }
        }

        .multicolumn-content-container {
            @include col(10);
        }
    }
}
