// Values based on https://www.gridlover.net/try
@mixin vertical-rhythm {
    h1 {
        margin-bottom: $spacing-5;
    }

    h2 {
        margin-top: $spacing-7;
        margin-bottom: $spacing-7;
    }

    h3 {
        margin-top: $spacing-7;
        margin-bottom: $spacing-6;
    }

    h4 {
        margin-top: $spacing-7;
        margin-bottom: $spacing-4;
    }

    h5 {
        margin-top: $spacing-7;
        margin-bottom: $spacing-5;
    }

    p,
    ul,
    ol,
    pre,
    table,
    .table-wrapper {
        margin-top: 0;
        margin-bottom: $spacing-5;
    }

    blockquote,
    q {
        margin: $spacing-5 0;
    }

    ul ul,
    ol ol,
    ul ol,
    ol ul {
        margin-top: $list-item-margin;
        margin-bottom: 0;
    }

    li:last-of-type {
        margin-bottom: 0;
    }

    @media (min-width: $screen-sm) {
        h1 {
            margin-bottom: $spacing-6;
        }

        h2 {
            margin-top: $spacing-8;
            margin-bottom: $spacing-8;
        }

        h3 {
            margin-top: $spacing-7;
            margin-bottom: $spacing-7;
        }

        h4 {
            margin-top: $spacing-8;
            margin-bottom: $spacing-6;
        }

        h5 {
            margin-top: $spacing-8;
            margin-bottom: $spacing-6;
        }

        p,
        ul,
        ol,
        pre,
        table,
        .table-wrapper {
            margin-bottom: $spacing-7;
        }

        blockquote,
        q {
            margin: $spacing-7 0;
        }

        ul ul,
        ol ol,
        ul ol,
        ol ul {
            margin-top: $list-item-margin;
        }
    }

    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }
}

.vertical-rhythm {
    @include vertical-rhythm;
}
