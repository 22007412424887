// Grid / Layout
// =======================================================================
$columns:    12;

$container-max-width:     $screen-lg; // how wide should the container be before it 'locks'
$container-padding-xs:    $spacing-5; // 16px
$container-padding-sm:    $spacing-6; // 24px
$container-padding-xlg:   $spacing-7; // 24px

// Gutter size per breakpoint
// NOTE: $container-padding-X and $x-gutter-width should match for each breakpoint.
$mobile-margin-bottom:                 $container-padding-xs; //16px
$desktop-gutter-width:                 $container-padding-sm; // 24px
$extra-large-desktop-gutter-width:     $container-padding-xlg; // 36px

// Debug colours
// NOTE: Colours have opacity so you can change to see the content of debug item.
$debug-section-colour:                     rgba($color-black, 1);
$debug-container-colour:                   rgba($color-success, 1);
$debug-row-colour:                         rgba($color-secondary-1, 1);
$debug-col-colour:                         rgba($color-primary-1, 1);

/*
* CONTAINER STYLES
*/
@mixin container {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: $container-max-width;
    padding: 0 $container-padding-xs;

    @media (min-width: $screen-sm) {
        padding: 0 $container-padding-sm;
    }

    @media (min-width: $screen-xlg) {
        padding: 0 $container-padding-xlg;
    }
}

.container {
    @include container();
}

/*
* ROW STYLES
*/
@mixin grid-reverse {
    flex-direction: row-reverse;
}


@mixin grid-center {
    justify-content: center;
}

@mixin row {
    /* IMPORTANT! Make sure the row is always 100% of the container, plus the breakpoint gutter width. That way every column is consistent and has a margin-right */
    width: 100%;

    &.grid-reverse {
        @include grid-reverse;
    }

    &.grid-center {
        @include grid-center;
    }

    @media (min-width: $screen-sm) {
        display: flex;
        flex: 0 1 auto;
        flex-wrap: wrap;
        width: calc(100% + #{$desktop-gutter-width});
    }

    // @media (min-width: $screen-xlg) {
    //     width: calc(100% + #{$extra-large-desktop-gutter-width});
    // }
}

.row {
    @include row();
}

/*
* COLUMN STYLES
*/
@mixin base-column($i, $gutter-width) {
    flex-direction: column;
    // flex-basis: calc((100% / #{$columns} * #{$i}) - #{$gutter-width} - 0.1px);
    // max-width: calc((100% / #{$columns} * #{$i}) - #{$gutter-width} - 0.1px);
    width: calc((100% / #{$columns} * #{$i}) - #{$gutter-width} - 0.1px); // the 0.1px is an IE11 bug fix so the last column doesn't wrap.
    margin-right: $gutter-width;
}

@mixin stack() {
    @media (max-width: 767px) {
        width: 100%;
        margin-bottom: $mobile-margin-bottom;
    }
}

@mixin col($i) {
    @include base-column($i, $desktop-gutter-width);
}

@mixin col-xlg($i) {
    @include base-column($i, $extra-large-desktop-gutter-width);
}

/*
* OFFSET STYLES
*/
@mixin base-offset($i, $breakpoint, $gutter-width, $reverse: false) {
    @media ($breakpoint) {
        @if $reverse == true {
            margin-right: calc((100% / #{$columns} * #{$i}) + #{$gutter-width}) !important;
        }
        @else {
            margin-left: calc((100% / #{$columns} * #{$i}));
        }
    }
}

@mixin col-offset($i, $reverse: false) {
    @include base-offset($i, 'min-width: #{$screen-sm}', $desktop-gutter-width, $reverse);
}

@mixin col-xlg-offset($i, $reverse: false) {
    @include base-offset($i, 'min-width: #{$screen-md}', $extra-large-desktop-gutter-width, $reverse);
}

/*
* DEBUG STYLES
* Pretty colours so you can figure out what's breaking.
*/
.grid-debug {
    background-color: $debug-section-colour;

    > .container {
        background-color: $debug-container-colour;

        > .row {
            background-color: $debug-row-colour;

            > div {
                background-color: $debug-col-colour;
            }
        }
    }
}
