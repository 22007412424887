// Accordions
//----------------------------------------
$accordion-icon-color:              $color-black;
$accordion-border-color:            $color-shade-1;

$accordion-heading-padding:         $spacing-5 $spacing-6;
$accordion-heading-color:           $color-black;
$accordion-heading-bg:              $color-white;
$accordion-heading-hover-bg:        $color-shade-2;

$accordion-item-open-margin-bottom: $spacing-6;

$accordion-content-padding:         $spacing-6;
$accordion-content-bg:              $color-white;
$accordion-content-color:           $color-black;
$accordion-content-transition:      all 200ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

.c-accordion {
    .accordion-item {
        margin-bottom: 0;
        transition: margin-bottom 0.4s ease;

        .accordion-heading {
            min-height: $spacing-9; //64px
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: $accordion-heading-padding;
            background-color: $accordion-heading-bg;
            box-shadow: inset 0 -1px 0 0 $accordion-heading-hover-bg;

            h4 {
                color: $accordion-heading-color;
                margin-bottom: 0;
            }

            .svg-icon {
                flex-shrink: 0;
                color: $accordion-icon-color;
                transition: $accordion-content-transition;
            }

            &:hover,
            &:focus {
                background-color: $accordion-heading-hover-bg;
                cursor: pointer;

                h4 {
                    text-decoration: underline;
                }
            }
        }

        .accordion-content {
            display: none;
            padding: $accordion-content-padding;
            width: 100%;
            background-color: $accordion-content-bg;
            color: $accordion-content-color;
        }

        &.open {
            margin-bottom: $accordion-item-open-margin-bottom;

            .accordion-heading {
                background-color: $accordion-heading-hover-bg;

                .svg-icon {
                    transform: rotateZ(180deg);
                }
            }
        }
    }
}
