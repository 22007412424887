$checkbox-size:                     $spacing-6;
$checkbox-icon:                     'tick';
$checkbox-icon-color:               $color-white;
$checkbox-icon-size:                $spacing-5;
$checkbox-checked-bg:               $color-primary-1;
$checkbox-label-padding-left:       $checkbox-size + $spacing-5;

$radio-border-radius:               50%;

.checkbox-wrapper input[type="checkbox"],
.checkboxlist-wrapper input[type="checkbox"],
.radiobutton-wrapper input[type="radio"] {
    position: absolute;
    width: $checkbox-size;
    height: $checkbox-size;
    z-index: 10;
    margin: 0;
    opacity: 0;

    + label {
        position: relative;
        display: block;
        padding-left: $checkbox-label-padding-left;
        min-height: $checkbox-size;
        line-height: $checkbox-size;
        margin-bottom: $label-margin-bottom;
        cursor: pointer;
        font-weight: normal;
    }


    + label::before {
        content: "";
        width: $checkbox-size;
        height: $checkbox-size;
        box-sizing: border-box;
        position: absolute;
        display: inline-block;
        left: 0;
        border: 1px solid $input-border-color;
        background-color: $input-bg;
        border-radius: $input-radius;
        transition:
            border-color $input-transition-speed $input-transition-curve,
            background-color $input-transition-speed $input-transition-curve;
    }

    &:focus + label::before {
        border-color: $checkbox-checked-bg;
    }

    &:checked + label::before {
        border-color: $checkbox-checked-bg;
        background-color: $checkbox-checked-bg;
    }

    &:checked + label {
        position: relative;
        @include bg-icon($checkbox-icon, after, $checkbox-icon-color) {
            position: absolute;
            top: $checkbox-icon-size / 4;
            left: $checkbox-icon-size / 4;
            width: $checkbox-icon-size;
            height: $checkbox-icon-size;
            content: "";
        }
    }

    // Remove Kentico rendered BR tags
    + label + br {
        display: none;
    }
}

.checkbox-wrapper .checkbox-item:last-of-type input + label {
    margin-bottom: 0;
}

.radiobutton-wrapper input[type="radio"] {
    + label::before {
        border-radius: $radio-border-radius;
    }

    &:checked + label::after {
        border-radius: $radio-border-radius;
    }
}

// Disabled states (because Kentico outputs terrible HTML)
input[type="checkbox"]:disabled + label,
input[type="radio"]:disabled + label {
    color: $input-disabled-color;
    cursor: default !important;

    &::before {
        color: $input-disabled-color;
        background-color: $input-disabled-bg;
    }
}

span.checkbox.aspNetDisabled,
span.radio.aspNetDisabled {
    > br {
        display: none;
    }

    span.aspNetDisabled {
        display: block;
        margin-bottom: $label-margin-bottom;
    }
}
