$form-layout-mobile-margin-bottom:    $spacing-5;
$form-layout-desktop-margin-bottom:    $spacing-6;

@mixin base-mobile-form-layouts {
    @include stack;
    margin-bottom: $form-layout-mobile-margin-bottom;
}

form,
.form-layout {
    .form-element,
    .form-alert {
        @include base-mobile-form-layouts;

        &.form-element-error {
            position: relative;
        }
    }
}

.EditingFormTable {
    > div {
        @include base-mobile-form-layouts;
    }
}

@mixin base-desktop-form-layout {
    @include col(12);
    margin-bottom: $form-layout-desktop-margin-bottom;
    display: flex;
    justify-content: flex-end;

    input[type="submit"] {
        width: 100%;
    }
}

@media (min-width: $screen-sm) {
    form,
    .EditingTableForm,
    .form-layout {
        .form-element,
        .form-alert {
            @include base-desktop-form-layout;
        }

        .form-layout-half {
            @include col(6);
        }

        .form-layout-half-cleared {
            @include col(12);
            padding-right: 50%;
        }

        .form-layout-third {
            @include col(4);
        }

        .form-layout-third-cleared {
            @include col(12);
            padding-right: 66.66%;
        }

        .form-layout-quarter {
            @include col(3);
        }

        .form-layout-quarter-cleared {
            @include col(12);
            padding-right: 75%;
        }
    }

    .EditingFormTable {
        @include row;

        > div {
            @include base-desktop-form-layout;
        }
    }
}
