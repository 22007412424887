// Lists
// ----------------------------------------
$list-item-padding:                 $spacing-4;
$list-item-margin:                  $spacing-3;
$list-bullet-width:                 $spacing-3;
$list-bullet-height:                $list-bullet-width;
$sublist-bullet-width:              $spacing-3;
$sublist-bullet-height:             $spacing-1;
$list-bullet-color:                 $color-black;

// Reset lists
//----------------------------------------
@mixin list-reset {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        padding: 0;
        margin-bottom: 0;

        &::before {
            display: none;
            content: '';
        }
    }
}

ul,
ol {
    list-style: none;
    padding-left: 0;

    li {
        position: relative;
        padding-left: calc(#{$list-bullet-width} + #{$list-item-padding});
        margin-bottom: $list-item-margin;
        line-height: $leading-normal;
    }

    ul,
    ol {
        margin-top: $list-item-margin;
    }
}

ul {
    li {
        &::before {
            position: absolute;
            left: 0;
            top: $spacing-3 + $spacing-1;
            content: '';
            display: block;
            width: $list-bullet-width;
            height: $list-bullet-height;
            border-radius: 100%;
            background-color: $list-bullet-color;
        }
    }

    // 2nd level
    ul {
        li {
            &::before {
                top: calc(#{$list-item-margin} + #{$spacing-2});
                width: $sublist-bullet-width;
                height: $sublist-bullet-height;
                border-radius: 0;
            }
        }
    }
}

ol {
    counter-reset: section;

    li {
        &::before {
            position: absolute;
            left: 0;
            counter-increment: section;
            content: counters(section, ".") ".";
            color: $list-bullet-color;
        }
    }

    // 2nd level
    ol {
        li {
            &::before {
                content: counter(section, lower-alpha) ".";
            }
        }
    }
}
