$textarea-min-height:               150px;

textarea {
    @include base-input-styles;
    height: auto;
    min-height: $textarea-min-height;
    resize: vertical;
    display: block;

    &:disabled {
        color: $input-disabled-color;
        background-color: $input-disabled-bg;
    }
}
