// Page controls
//----------------------------------------
$page-control-spacing:                    $spacing-6;

$print-control-icon-margin-right:         $spacing-5;
$print-control-color:                     $color-primary-1;
$print-control-hover-color:               $color-black;

$social-share-trigger-icon-margin-right:    $spacing-5;
$social-share-trigger-icon-color:           $color-primary-1;
$social-share-trigger-icon-hover-color:     $color-black;

$social-share-links-z-index:                5;
$social-share-link-width:                   $spacing-8;
$social-share-total:                        7;
$social-share-links-top-spacing:            $spacing-5;
$social-share-links-left-spacing:           $spacing-8;

$social-share-bg:                           $color-primary-1;
$social-share-icon-color:                   $color-white;
$social-share-email-bg-hover:               $color-primary-3;

.c-print-social-share {
    display: flex;
    align-items: center;

    > div {
        margin-right: $page-control-spacing;

        &:last-of-type {
            margin-right: 0;
        }
    }

    .print-control .print-control-button {
        display: flex;
        align-items: center;
        color: $print-control-color;
        padding: 0;
        cursor: pointer;

        .svg-icon {
            margin-right: $print-control-icon-margin-right;
            color: $print-control-color;
        }

        &:hover,
        &:focus {
            color: $print-control-hover-color;

            .svg-icon {
                color: $print-control-hover-color;
            }
        }
    }

    .share-control {
        padding: 0;

        .social-share-trigger {
            display: flex;
            align-items: center;
            color: $social-share-trigger-icon-color;
            cursor: pointer;

            > .svg-icon {
                color: $social-share-trigger-icon-color;
                margin-right: $social-share-trigger-icon-margin-right;
            }

            &:hover,
            &:focus {
                color: $social-share-trigger-icon-hover-color;
                text-decoration: underline;

                > .svg-icon {
                    color: $social-share-trigger-icon-hover-color;
                }
            }
        }

        .share-links {
            width: 100%;
            overflow: hidden;
            transform: translateY(50%);
            opacity: 0;
            background-color: $social-share-bg;
            transition: all 200ms ease-out;
            z-index: $social-share-links-z-index;
            border-radius: $spacing-7;

            .share-link {
                display: none;
                justify-content: center;
                align-items: center;
                width: $social-share-link-width;
                flex-grow: 1;
                height: $social-share-link-width;
                opacity: 0;
                background-color: $social-share-bg;
                transition: opacity 150ms ease, background-color 100ms ease-in;

                &:hover,
                &:focus {
                    transition-delay: 0s !important;
                }

                &.share-facebook {
                    &:hover,
                    &:focus {
                        background-color: $color-facebook;
                    }
                }

                &.share-google {
                    &:hover,
                    &:focus {
                        background-color: $color-google;
                    }
                }

                &.share-linkedin {
                    &:hover,
                    &:focus {
                        background-color: $color-linkedin;
                    }
                }

                &.share-pinterest {
                    &:hover,
                    &:focus {
                        background-color: $color-pinterest;
                    }
                }

                &.share-twitter {
                    &:hover,
                    &:focus {
                        background-color: $color-twitter;
                    }
                }

                &.share-email {
                    &:hover,
                    &:focus {
                        background-color: $social-share-email-bg-hover;
                    }
                }

                &.close-share-links {
                    &:hover,
                    &:focus {
                        background-color: $social-share-email-bg-hover;
                    }
                }
            }

            @for $i from 1 through $social-share-total {
                .share-link:nth-child(#{$i}) {
                    transition-delay: (50ms * $i);
                }
            }

            &.is-visible {
                transform: translateY(0);
                opacity: 1;

                .share-link {
                    opacity: 1;
                    display: flex;
                }
            }

            .svg-icon {
                color: $social-share-icon-color;
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .share-control {
        flex-wrap: wrap;

        .share-links {
            display: flex;
            flex-basis: 100%;
            justify-content: space-around;

            &.is-visible {
                margin-top: $spacing-5;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .c-print-social-share {
        justify-content: flex-end;

        .share-control {
            position: relative;

            .share-links {
                position: absolute;
                top: $social-share-links-top-spacing;
                left: $social-share-links-left-spacing;
                bottom: auto;
                right: auto;
                width: auto;
                opacity: 0;
                height: 0;
                min-height: 0;
                overflow: hidden;
                transform: translateY($spacing-5);
                background-color: $social-share-bg;
                transition: all 200ms ease-out;

                &.is-visible {
                    height: auto;
                    max-height: 500px;
                    opacity: 1;
                    transform: translateY($spacing-6);
                }
            }
        }
    }
}
