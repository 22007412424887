// HEADING STYLES
//----------------------------------------
$base-heading-font:             $Barlow;
$base-heading-colour:           $color-black;
$base-heading-colour-light:     $color-white;

//! Why SASS Maps? https://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
$display-jumbo-type: (
    null: ($text-6xl, $leading-none),
    md: ($text-7xl, $leading-none)
);

$display-extra-extra-large-type: (
    null: ($text-4xl, $leading-none),
    md: ($text-5xl, $leading-none)
);

$display-extra-large-type: (
    null: ($text-3xl, $leading-tight),
    md: ($text-4xl, $leading-tight)
);

$display-large-type: (
    null: ($text-2xl, $leading-tight),
    md: ($text-3xl, $leading-tight)
);

$display-medium-type: (
    null: ($text-xl, $leading-tight),
    md: ($text-2xl, $leading-tight)
);


$display-small-type: (
    null: ($text-lg, $leading-tight),
    md: ($text-xl, $leading-tight)
);

@mixin base-display-styles {
    font-family: $base-heading-font;
    color: $base-heading-colour;
    font-weight: $font-medium;
}

@mixin display-jumbo {
    @include font-size($display-jumbo-type);
    @include base-display-styles;
    font-weight: $font-bold;
}

@mixin display-extra-extra-large {
    @include font-size($display-extra-extra-large-type);
    @include base-display-styles;
    font-weight: $font-bold;
}

@mixin display-extra-large {
    @include font-size($display-extra-large-type);
    @include base-display-styles;
}

@mixin display-large {
    @include font-size($display-large-type);
    @include base-display-styles;
}

@mixin display-medium {
    @include font-size($display-medium-type);
    @include base-display-styles;
}

@mixin display-small {
    @include font-size($display-small-type);
    @include base-display-styles;
}

.display-jumbo,
h1 {
    @include display-jumbo;
}

.display-extra-extra-large,
h2 {
    @include display-extra-extra-large;
}

.display-extra-large,
h3 {
    @include display-extra-large;
}

.display-large,
h4 {
    @include display-large;
}

.display-medium,
h5 {
    @include display-medium;
}
