$select-icon:                       'chevron-down';
$select-icon-color:                 $color-primary-1;
$select-icon-width:                 $spacing-6;
$select-icon-padding:               $spacing-8;

.select-wrapper {
    select,
    .select {
        appearance: none;
        @include base-input-styles;
        padding-right: $select-icon-padding;

        &:focus {
            outline: none;
            border-color: $input-border-color-focus;
        }

        &:disabled {
            color: $input-disabled-color;
            background-color: $input-disabled-bg;
        }
    }
}

.select-wrapper .input-wrapper,
.select-wrapper .EditingFormValueCell > div {
    position: relative;

    @include bg-icon($select-icon, after, $select-icon-color) {
        position: absolute;
        top: $select-icon-width / 2;
        right: $select-icon-width / 2;
        width: $select-icon-width;
        height: $select-icon-width;
        content: "";
        text-align: center;
    }
}

.select-wrapper.select-disabled .EditingFormValueCell > div::after {
    opacity: 0.5;
}
