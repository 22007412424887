// Icons
//----------------------------------------
$base-icon-width:                   $spacing-6; //24px
$base-icon-height:                  $spacing-6; //24px

$icon-color-option:                 'fill'; // can be changed to stroke

$bg-icon-path:                      './src/icons/optimised/';

// Mixins for adding icons to elements using CSS backgrounds
// set background to svg file
@mixin icon-styles($i, $color) {
    background: transparent center no-repeat;
    background-image: svg-url(inline-image('#{$bg-icon-path}#{$i}.svg'), $color);
    background-size: cover;
}

// eg: @include bg-icon(checkmark);
// eg: @include bg-icon(chevron-down, after);
@mixin bg-icon($icon, $position: '', $color: 'currentColor') {
    @if $position != '' {
        &:#{$position} {
            @content;
            @include icon-styles($icon, $color);
            pointer-events: none;
        }
    }

    @else {
        @content;
        @include icon-styles($icon, $color);
    }
}

@mixin icon-fill($color: 'currentColor') {
    @if type-of($color) == string {
        fill: unquote($color);
    }
    @else {
        fill: $color;
    }
    stroke: transparent;
}

@mixin icon-stroke($color: 'currentColor') {
    fill: transparent;

    @if type-of($color) == string {
        stroke: unquote($color);
    }
    @else {
        stroke: $color;
    }
}

svg {
    @if ($icon-color-option == 'stroke') {
        @include icon-stroke;
    }
    @else {
        @include icon-fill; // default
    }
    stroke-linecap: round;
    stroke-linejoin: round;
    will-change: fill, stroke;
}

// Base class
.svg-icon {
    position: relative;
    display: inline-block;
    max-width: 100%;
    max-height: 100%;
    width: $base-icon-width;
    height: $base-icon-height;
    cursor: pointer;
}

.svg-icon-sm {
    width: $base-icon-width / 2;
    height: $base-icon-height / 2;
}

// Social icons
.icon-facebook {
    fill: $color-facebook;
}

.icon-google {
    fill: $color-google;
}

.icon-instagram {
    fill: $color-instagram;
}

.icon-linkedin {
    fill: $color-linkedin;
}

.icon-twitter {
    fill: $color-twitter;
}

.icon-youtube {
    fill: $color-youtube;
}
