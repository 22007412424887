// Breakpoints
// =======================================================================
$screen-xs:                                480px;
$screen-sm:                                768px;
$screen-md:                                1024px;
$screen-lg:                                1220px;
$screen-xlg:                               1600px;

$screen-xsm-max:                           ($screen-xs - 1);
$screen-xs-max:                            ($screen-sm - 1);
$screen-sm-max:                            ($screen-md - 1);
$screen-md-max:                            ($screen-lg - 1);
$screen-lg-max:                            ($screen-xlg - 1);
