// Elevation Shadow System
//
// Based on the Refactoring UI shadows, pages 161
// ----------------------------------------
$shadow-levels: (
    1: 0 1px 3px hsla(0, 0%, 0%, 0.2),
    2: 0 4px 6px hsla(0, 0%, 0%, 0.2),
    3: 0 5px 15px hsla(0, 0%, 0%, 0.2),
    4: 0 10px 24px hsla(0, 0%, 0%, 0.2),
    5: 0 15px 35px hsla(0, 0%, 0%, 0.2)
);

@mixin shadow-level($level, $secondLevel: null) {
    @if $secondLevel != null {
        box-shadow: map-get($shadow-levels, $level), map-get($shadow-levels, $secondLevel);
    }
    @else {
        box-shadow: map-get($shadow-levels, $level);
    }
}

@each $level, $value in $shadow-levels {
    .shadow-level-#{$level} {
        @include shadow-level($level);
    }

    @each $secondLevel, $value in $shadow-levels {
        .shadow-level-#{$level}-#{$secondLevel} {
            @include shadow-level($level, $secondLevel);
        }
    }
}
