.s-text-block {
    .s-text-block-content {
        @include vertical-rhythm;
    }

    &.xl-text {
        .s-text-block-content p {
            @include body-lg;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-text-block {
        .row {
            @include grid-center;
        }

        .s-text-block-content {
            @include col(11);
        }
    }
}

@media (min-width: $screen-md) {
    .s-text-block {
        .s-text-block-content {
            @include col(8);
        }
    }
}
