// $type-breakpoints var used in responsive mapped-font-size mixin
// add more breakpoints here if you need to for responsive typography.
$type-breakpoints: (
    sm: $screen-sm,
    md: $screen-md,
    lg: $screen-lg,
    xlg: $screen-xlg
);

// Convert pixels into rems
// $baseline is set as 16px by default in scss/1-core/typography/_base-type.scss
@function calculate-rem($size) {
    @return ($size / $base-font-unit) * 1rem;
}

// Utility function for mixin font-size
@mixin make-font-size($font-size-type) {
    // Should always include the font-size and line-height.
    @if type-of($font-size-type) == "list" {
        font-size: calculate-rem(nth($font-size-type, 1));
        line-height: nth($font-size-type, 2);
    }
    @else {
        @error "Your missing line-height in your font-size object. Please add it in.";
    }
}

// Output sass mapped type variables into responsive typography
// https://www.smashingmagazine.com/2015/06/responsive-typography-with-sass-maps/
@mixin font-size($fs-map, $fs-breakpoints: $type-breakpoints) {
    // for responsive typography.
    @each $fs-breakpoint, $fs-type in $fs-map {
        @if $fs-breakpoint == null {
            @include make-font-size($fs-type);
        }
        @else {
            // If $fs-type is a key that exists in
            // $fs-breakpoints, use the value
            @if map-has-key($fs-breakpoints, $fs-breakpoint) {
                $fs-breakpoint: map-get($fs-breakpoints, $fs-breakpoint);
            }
            @media screen and (min-width: $fs-breakpoint) {
                @include make-font-size($fs-type);
            }
        }
    }
}
