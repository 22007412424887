$input-color:                       $color-black;
$input-bg:                          $color-white;
$input-height:                      $spacing-8; //48px
$input-padding:                     $spacing-4 $spacing-5;
$input-radius:                      0;
$input-border-color:                $color-shade-2;
$input-border-color-focus:          $color-primary-1;

$input-disabled-color:              $color-shade-3;
$input-disabled-bg:                 $color-shade-1;

$input-transition-speed:            0.2s;
$input-transition-curve:            ease-in-out;

@mixin base-input-styles {
    width: 100%;
    min-height: $input-height;
    padding: $input-padding;
    border: 1px solid $input-border-color;
    border-radius: $input-radius;
    color: $input-color;
    background-color: $input-bg;
    transition: border-color $input-transition-speed $input-transition-curve;

    &:focus {
        outline: none;
        border-color: $input-border-color-focus;
    }
}

input:not([type="submit"]) {
    @include base-input-styles;

    &:disabled {
        color: $input-disabled-color;
        background-color: $input-disabled-bg;
    }
}
