.c-text-icon-tile {
    display: -ms-grid;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: flex-start;
    grid-gap: $spacing-5;
    @include ie-grid(auto 1fr, true, 1, $spacing-5);

    .text-icon-tile-icon {
        width: $spacing-9;
        height: $spacing-9;
        border-radius: 50%;
        background-color: $grey-400;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            width: 70%;
            height: 70%;
        }
    }

    .text-icon-tile-content {
        @include vertical-rhythm;

        h3 {
            @include display-large;
            margin-bottom: $spacing-6;
        }

        > *:last-child {
            margin-bottom: 0;
        }
    }

    &:not(:last-of-type) .text-icon-tile-content {
        border-bottom: 1px solid $grey-400;
        padding-bottom: $spacing-7;
    }
}

@media (min-width: $screen-sm) {
    .c-text-icon-tile {
        grid-gap: $spacing-8;

        .text-icon-tile-icon {
            width: $spacing-13;
            height: $spacing-13;
        }
    }
}
