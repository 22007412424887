// Transitions & Animations
$base-transition-fast:                   0.1s;
$base-transition-medium:                 0.2s;
$base-transition-slow:                   0.3s;
$base-transition-curve:                  ease-in-out;

.fade-in {
    visibility: visible;
    opacity: 1;
}

.fade-in-out {
    &-appear {
        opacity: 0.01;
        transition: opacity $base-transition-medium $base-transition-curve;
    }

    &.fade-in-out-appear-active {
        opacity: 1;
    }

    &-enter {
        opacity: 0.01;
        transition: opacity $base-transition-medium $base-transition-curve;
    }

    &-enter.fade-in-out-enter-active {
        opacity: 1;
    }

    &-leave {
        opacity: 1;
        transition: opacity $base-transition-medium $base-transition-curve;
    }

    &-leave.fade-in-out-leave-active {
        opacity: 0.01;
    }
}

.slide-down-up {
    overflow: hidden;

    &-appear {
        height: 0;
        max-height: 0;
        visibility: hidden;
        transition: all $base-transition-slow $base-transition-curve;
    }

    &.slide-down-up-appear-active {
        height: auto;
        max-height: 600px;
        visibility: visible;
    }

    &-enter {
        height: 0;
        max-height: 0;
        visibility: hidden;
        transition: all $base-transition-slow $base-transition-curve;
    }

    &-enter.slide-down-up-enter-active {
        height: auto;
        max-height: 600px;
        visibility: visible;
    }

    &-leave {
        height: auto;
        max-height: 600px;
        visibility: visible;
        transition: all $base-transition-medium $base-transition-curve;
    }

    &-leave.slide-down-up-leave-active {
        height: 0;
        max-height: 0;
        visibility: hidden;
    }
}
