// Vertically center content block in relatively
// positioned parent
//----------------------------------------
@mixin vertical-center {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    transform: translateY(-50%);
}

@mixin horizontal-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

@mixin center-all {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}

// Visual helpers
//----------------------------------------
@mixin visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: none;
}

@mixin hidden {
    display: none;
}

.sr-only {
    @include visually-hidden;
}

.hidden {
    @include hidden;
}

.hidden-xs {
    @media (max-width: $screen-xs-max) {
        @include hidden;
    }
}

.hidden-sm {
    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        @include hidden;
    }
}

.hidden-md {
    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        @include hidden;
    }
}

.hidden-lg {
    @media (min-width: $screen-lg) {
        @include hidden;
    }
}

.no-scroll {
    overflow: hidden;
}

//! Apply styles to IE11 ONLY
@mixin forIE {
    @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        @content;
    }
}

@mixin generate-ie-grid-item($nth, $col, $row) {
    > *:nth-child(#{$nth}) {
        -ms-grid-column: $col;
        -ms-grid-row: $row;
    }
}

/* Create a CSS Grid set up for IE11 with auto placement. */
@mixin ie-grid($columns: 1fr 1fr, $gutter: true, $max-row: 1, $gap: $desktop-gutter-width) {
    $ms-grid-column: ();
    //* If there's a gutter, convert the columns string into a version with extra columns for the grid-gap.
    @if $gutter == true {
        @for $i from 1 through length($columns) {
            $ms-grid-column: append($ms-grid-column, nth($columns, $i), space);
            //* don't add a grid gap in the final column
            @if $i != length($columns) {
                $ms-grid-column: append($ms-grid-column, $gap, space);
            }
        }
    }

    @include forIE {
        @if $gutter == true {
            -ms-grid-columns: $ms-grid-column;
        }
        @else {
            -ms-grid-columns: $columns;
        }

        @if $gutter == true {
            > * {
                margin-bottom: $gap;
            }
        }
    }

    //* Auto place all the IE CSS Grid items. Assumes you want items placed left to right, top to bottom. Anything else you'll need custom CSS.
    $i: 0;
    $row: 0;
    @for $row from 1 through $max-row {
        $col: 0;
        @for $col from 1 through length($columns) {
            $i: $i + 1;
            @if $gutter == true {
                @include generate-ie-grid-item($i, $col * 2 - 1, $row);
            }
            @else {
                @include generate-ie-grid-item($i, $col, $row);
            }
        }
    }
}
