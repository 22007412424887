.s-text-media {
    .media-wrapper {
        margin-bottom: $spacing-8;

        .text-media-icon {
            border-radius: 50%;
            width: $spacing-14;
            height: $spacing-14;
            background: $grey-200;
            display: flex;
            justify-content: center;
            align-items: center;
            @include shadow-level(2);

            svg {
                width: $spacing-12;
                height: $spacing-12;
            }
        }
    }

    .text-wrapper {
        @include vertical-rhythm;

        a {
            margin-top: $spacing-5;
        }
    }

    &.bg-theme-2 {
        @include base-section-spacing-default('padding');

        .media-wrapper .text-media-icon {
            background: $color-white;

            svg {
                margin-top: -($spacing-5 + $spacing-2);
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-text-media {
        .row {
            @include grid-center;

            .grid-container {
                @include col(9);
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-text-media {
        .row .grid-container {
            display: flex;
            flex-direction: row;
        }

        .media-wrapper {
            margin-right: $spacing-9;

            .text-media-icon {
                border-radius: 50%;
                width: $spacing-16;
                height: $spacing-16;
                background: $grey-200;
                display: flex;
                justify-content: center;
                align-items: center;

                svg {
                    width: $spacing-13;
                    height: $spacing-13;
                }
            }
        }

        &.bg-theme-2 {
            .row .grid-container {
                @include grid-reverse;

                .media-wrapper {
                    margin-right: 0;
                    margin-left: $spacing-9;
                }
            }
        }
    }
}
