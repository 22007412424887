// GLOBAL SECTION STYLES
//----------------------------------------
$section-spacing-sm-mobile:               $spacing-7;
$section-spacing-default-mobile:          $spacing-9;
$section-spacing-lg-mobile:               $spacing-10;

$section-spacing-sm-desktop:              $spacing-9;
$section-spacing-default-desktop:         $spacing-11;
$section-spacing-lg-desktop:              $spacing-13;

@mixin base-section-spacing-sm($unit: 'margin') {
    @if $unit == 'padding' {
        padding: $section-spacing-sm-mobile 0;

        @media (min-width: $screen-sm) {
            padding: $section-spacing-sm-desktop 0;
        }
    }
    @else {
        margin-bottom: $section-spacing-sm-mobile;

        @media (min-width: $screen-sm) {
            margin-bottom: $section-spacing-sm-desktop;
        }
    }
}

@mixin base-section-spacing-default($unit: 'margin') {
    @if $unit == 'padding' {
        padding: $section-spacing-default-mobile 0;

        @media (min-width: $screen-sm) {
            padding: $section-spacing-default-desktop 0;
        }
    }
    @else {
        margin-bottom: $section-spacing-default-mobile;

        @media (min-width: $screen-sm) {
            margin-bottom: $section-spacing-default-desktop;
        }
    }
}

@mixin base-section-spacing-lg($unit: 'margin') {
    @if $unit == 'padding' {
        padding: $section-spacing-lg-mobile 0;

        @media (min-width: $screen-sm) {
            padding: $section-spacing-lg-desktop 0;
        }
    }
    @else {
        margin-bottom: $section-spacing-lg-mobile;

        @media (min-width: $screen-sm) {
            margin-bottom: $section-spacing-lg-desktop;
        }
    }
}

section[class*='s-'] {
    // give all sections default margin-bottoms
    @include base-section-spacing-default;

    &.no-margin-bottom {
        margin-bottom: 0;
    }
}
