// 1. Set where the font files live
// =======================================================================
$font-path:         '../assets/fonts';

@import url('https://fonts.googleapis.com/css?family=Barlow+Condensed:400,500,600,700,800,900|Open+Sans:400,500,600,800&display=swap');

// 2. Reference any web safe fonts
// =======================================================================
$helvetica:         'Helvetica Neue', Helvetica, Arial, sans-serif;
$helvetica-bold:    'HelveticaNeue-Bold', 'Helvetica Neue Bold', 'Helvetica Neue', Helvetica, Arial, sans-serif;

// 3. Add Font Face CSS for custom font files
// =======================================================================
@mixin fontFace($name, $filename, $id, $weight: 'normal') {
    @font-face {
        font-family: '#{$name}';
        src: url('#{$font-path}/#{$filename}.eot');
        src:
            url('#{$font-path}/#{$filename}.eot?#iefix') format('embedded-opentype'),
            url('#{$font-path}/#{$filename}.woff2') format('woff2'),
            url('#{$font-path}/#{$filename}.woff') format('woff'),
            url('#{$font-path}/#{$filename}.ttf')  format('truetype'),
            url('#{$font-path}/#{$filename}.svg##{$id}') format('svg');
        font-weight: $weight;
        font-style: normal;
    }
}

// Name, filename, svgId, weight (optional)
// @include fontFace('Montserrat Regular', 'Montserrat-Regular', 'MontserratRegular');
// @include fontFace('Montserrat Bold', 'Montserrat-Bold', 'MontserratBold');

// 4. Declare custom font stack w/ websafe fallback
// =======================================================================
$Barlow: 'Barlow Condensed', $helvetica-bold;
$OpenSans: 'Open Sans', $helvetica-bold;
