$label-font-weight:                700;
$label-color:                      $color-black;
$label-margin-bottom:              $spacing-3;

label,
.FieldLabel span { //Kentico uses both tags for labels inconsistently
    font-weight: $label-font-weight;
    color: $label-color;
    margin-bottom: $label-margin-bottom;
    display: block;
}

.is-required label sup {
    color: $color-error;
}
