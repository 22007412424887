// Relative Spacing System
//
// Based on Refactoring UI spacing system - page 63
// =======================================================================
$base-spacing-unit:     16px;

$spacing-1:        0.125 * $base-spacing-unit; //2px
$spacing-2:        0.25 * $base-spacing-unit;  //4px
$spacing-3:        0.5 * $base-spacing-unit;   //8px
$spacing-4:        0.75 * $base-spacing-unit;  //12px
$spacing-5:        1 * $base-spacing-unit;     //16px
$spacing-6:        1.5 * $base-spacing-unit;   //24px
$spacing-7:        2 * $base-spacing-unit;     //32px
$spacing-8:        3 * $base-spacing-unit;     //48px
$spacing-9:        4 * $base-spacing-unit;     //64px
$spacing-10:       5 * $base-spacing-unit;     //80px
$spacing-11:       6 * $base-spacing-unit;     //96px
$spacing-12:       7 * $base-spacing-unit;     //112px
$spacing-13:       8 * $base-spacing-unit;     //128px
$spacing-14:       9 * $base-spacing-unit;     //144px
$spacing-15:       10 * $base-spacing-unit;     //160px
$spacing-16:       11 * $base-spacing-unit;     //176px
$spacing-17:       12 * $base-spacing-unit;     //192px
