// Relative Font Sizing System
//
// Based on Refactoring UI font sizing system - page 91
// =======================================================================
$base-font-unit: 16px;  // used for rem calculations.

$text-xs:      0.75 * $base-font-unit;  //12px
$text-sm:      0.875 * $base-font-unit; //14px
$text-base:    1 * $base-font-unit;     //16px
$text-lg:      1.125 * $base-font-unit; //18px
$text-xl:      1.25 * $base-font-unit;  //20px
$text-2xl:     1.5 * $base-font-unit;   //24px
$text-3xl:     1.875 * $base-font-unit; //30px
$text-4xl:     2.25 * $base-font-unit;  //36px
$text-5xl:     3 * $base-font-unit;     //48px
$text-6xl:     3.75 * $base-font-unit;  //60px
$text-7xl:     4.5 * $base-font-unit;   //72px

// Line Height spacing system.
//
// Based on Refactoring UI line-height standards - page 105
// Line height is inversely porportional to font-size.
// E.g. Small text needs large line-height, large text needs small line-height.

// There's no system in the book, values ripped from Tailwind CSS https://tailwindcss.com/docs/line-height
// =======================================================================
$leading-none:      1;
$leading-tight:     1.15;
$leading-snug:      1.375;
$leading-normal:    1.5;
$leading-relaxed:   1.625;
$leading-loose:     2;


// Font Weight References
// =======================================================================
$font-thin:          100;
$font-extra-light:   200; // AKA Ultra Light
$font-light:         300;
$font-normal:        400;
$font-medium:        500;
$font-semibold:      600;
$font-bold:          700;
$font-extrabold:     800;
$font-black:         900; // AKA know as Heavy

// Base Site Setup
// =======================================================================
$base-font-family:          $OpenSans; // taken from font-stacks.scss

$base-type: (
    null: ($text-base, $leading-normal),
    sm: ($text-lg, $leading-normal)
);

html {
    font-size: 100%;
}

body {
    font-family: $base-font-family;
    @include font-size($base-type);
    background-color: $color-white;
    color: $color-black;
}
