// Form alert styling
.form-alert,
.InfoLabel,
.ErrorLabel {
    display: block;
    width: 100%;
    padding: $spacing-5;
    margin-bottom: $spacing-5;
    background-color: $color-white;
}

.InfoLabel,
.form-alert.success {
    border: 1px solid $color-success;
    color: $color-success;
    text-align-last: auto;
}

.ErrorLabel,
.form-alert.error {
    border: 1px solid $color-error;
    color: $color-error;
    text-align: center;
}
