$multicolumn-icon-size:         $spacing-9;
$multicolumn-icon-size-desktop: $spacing-11;
$multicolumn-alt-background:    $color-white;
$multicolumn-alt-padding:       $spacing-5;

.c-multicol-content-tile {
    background-color: $color-white;
    padding: $spacing-7;
    border-radius: $spacing-2;
    @include shadow-level(1, 3);

    .multicol-content-tile-icon {
        margin-left: -$spacing-5;

        .svg-icon,
        img {
            width: $multicolumn-icon-size;
            height: $multicolumn-icon-size;
            min-width: $multicolumn-icon-size;
            min-height: $multicolumn-icon-size;
        }
    }

    .multicol-content-tile-icon .svg-icon {
        fill: $grey-800;
    }

    .multicol-content-tile-heading {
        margin-bottom: $spacing-4;
    }

    a {
        @include btn-skin-1;
        margin-top: $spacing-7;
        @include shadow-level(2);
    }
}

@media (max-width: $screen-sm-max) {
    .c-multicol-content-tile {
        display: -ms-grid;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: $spacing-5;
        @include ie-grid(auto 1fr, true, 1, $spacing-5);
    }
}

@media (min-width: $screen-sm) {
    .c-multicol-content-tile {
        padding: $spacing-7;

        .multicol-content-tile-icon {
            margin-bottom: $spacing-6;
            margin-left: 0;

            .svg-icon {
                width: $multicolumn-icon-size-desktop;
                height: $multicolumn-icon-size-desktop;
                min-width: $multicolumn-icon-size-desktop;
                min-height: $multicolumn-icon-size-desktop;
            }
        }

        .multicol-content-tile-heading {
            margin-bottom: $spacing-6;
        }

        // .multicol-content-tile-text {
        //     margin-bottom: $spacing-5;
        // }
    }
}
