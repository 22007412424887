#mobile-menu {
    display: none;
}

#mobile-menu.slideout-menu {
    position: fixed;
    left: auto;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 256px;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    display: none;
    background-color: $grey-900;
    padding-top: $spacing-10;

    a {
        @include display-large;
        color: $color-white;
        text-decoration: none;
        margin-bottom: $spacing-8;

        &:last-of-type {
            @include btn-skin-3;
        }

        &:not(:last-of-type) {
            @include fancy-underline($color-white);
        }
    }
}

.slideout-panel {
    position: relative;
    z-index: 10;
    will-change: transform;
    background: $color-white;
}

.slideout-open,
.slideout-open body,
.slideout-open .slideout-panel {
    overflow: hidden;
}

.slideout-open #mobile-menu.slideout-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
