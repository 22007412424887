$s-masthead-bg-color:               $color-shade-3;
$masthead-heading-margin:           $spacing-7;
$masthead-heading-margin-desktop:   $spacing-8;

section.s-masthead {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include base-section-spacing-lg('padding');
    position: relative;

    .container {
        position: relative;
    }

    .masthead-overlay {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(rgba($color-primary-3, 0.6), rgba($color-primary-3, 0.6)), linear-gradient($color-primary-3, transparent 60%);
    }

    .masthead-title {
        margin-bottom: $spacing-7;
        text-align: center;

        h1 {
            text-align: center;
            color: $color-white;
            margin-bottom: $masthead-heading-margin;
            line-height: $leading-none;
        }

        h2 {
            @include display-large;
            font-weight: $font-medium;
            text-align: center;
            margin-bottom: 0;
            color: $color-white;
            line-height: $leading-snug;
        }
    }
}

@media (min-width: $screen-sm) {
    section.s-masthead {
        // padding-top: $spacing-13 + $spacing-5;
        // padding-bottom: $spacing-11;

        .row {
            @include grid-center;
        }

        .masthead-title {
            @include col(8);

            h1 {
                margin-bottom: $masthead-heading-margin-desktop;
            }

            h2 {
                line-height: $leading-tight;
            }
        }
    }
}
