$table-border: 1px solid $grey-400;
$table-padding: $spacing-6;

.s-comparison {
    @include bg-theme-2;
    @include base-section-spacing-default('padding');

    .comparison-table {
        h2 {
            margin-bottom: $spacing-8;
            padding: 0 $table-padding;
        }

        .table-row {
            display: -ms-grid;
            display: grid;
            grid-template-columns: 1fr;
            @include ie-grid(1fr, true, 10, 0);

            &.table-header {
                display: none;
            }

            .row-item {
                @include vertical-rhythm;
                border-bottom: $table-border;
                padding: $table-padding;

                &.highlight {
                    background: $grey-300;
                    grid-column: 1 / -1;

                    > *:first-child {
                        margin-bottom: $spacing-5;
                    }
                }

                h3,
                h4 {
                    margin-top: 0;
                }

                svg {
                    width: $spacing-8;
                    height: $spacing-8;
                    display: block;
                    max-width: inherit;
                    max-height: inherit;
                    margin-bottom: $spacing-4;
                }

                &:nth-of-type(2) {
                    svg {
                        color: $grey-500;
                        margin-left: -$spacing-3;
                    }
                }

                &:last-of-type {
                    svg {
                        color: $grey-700;
                    }
                }

                p:last-child {
                    margin-bottom: 0;
                }
            }

            &.table-footer {
                .row-item {
                    border-bottom: none;

                    a {
                        width: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .s-comparison {
        .container {
            padding: 0;
        }

        .comparison-table .table-row .row-time.table-footer {
            &:first-of-type {
                padding-top: $spacing-8;
                padding-bottom: $spacing-8;
            }

            &:last-of-type {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-comparison {
        .row {
            @include grid-center;

            .comparison-table {
                @include col(12);

                .table-row {
                    grid-template-columns: 1fr 1fr;

                    .row-item:last-of-type {
                        border-left: $table-border;
                    }

                    &.table-footer {
                        align-items: center;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    .s-comparison .row .comparison-table {
        .table-row:not(.table-footer) {
            @include ie-grid(1fr 1fr, true, 10, 0);

            .row-item.highlight {
                -ms-grid-column: 1;
                -ms-grid-column-span: 3;
                -ms-grid-row: 1;
            }

            .row-item:nth-of-type(2) {
                -ms-grid-column: 1;
                -ms-grid-span: 2;
                -ms-grid-row: 2;
            }

            .row-item:last-of-type {
                -ms-grid-column: 3;
                -ms-grid-span: 2;
                -ms-grid-row: 2;
            }
        }

        .table-row.table-footer {
            @include ie-grid(1fr 1fr, true, 10, 0);
        }
    }
}

@media (min-width: $screen-md) {
    .s-comparison {
        .row {
            .comparison-table {
                @include col(12);

                .table-row {
                    grid-template-columns: 1fr 1fr 1fr;
                    @include ie-grid(1fr 1fr 1fr, true, 10, 0);

                    &.table-header {
                        display: -ms-grid;
                        display: grid;
                        @include ie-grid(1fr 1fr 1fr, true, 1, 0);

                        .row-item:first-of-type {
                            grid-column: 2 / 3;
                            -ms-grid-column: 3;
                            -ms-grid-span: 2;
                        }

                        .row-item:last-of-type {
                            -ms-grid-column: 5;
                            -ms-grid-span: 2;
                        }
                    }

                    .row-item.highlight {
                        grid-column: 1 / 2;
                        -ms-grid-column: 1;
                        -ms-grid-span: 2;
                    }

                    &:not(:first-of-type) .row-item h4 {
                        display: none;
                    }

                    .row-item:nth-last-of-type(2) {
                        border-left: $table-border;
                    }

                    &.table-footer {
                        .row-item:first-of-type {
                            grid-column: 2 / 3;
                            -ms-grid-column: 3;
                            -ms-grid-column-span: 2;
                        }

                        .row-item:last-of-type {
                            -ms-grid-column: 5;
                            -ms-grid-column-span: 2;
                        }
                    }
                }
            }
        }
    }
}
