section.s-page-ctas {
    @include base-section-spacing-default('padding');
    @include bg-theme-1;
    color: $grey-200;
    margin-bottom: 0;

    h2 {
        color: $grey-200;
    }

    .heading-copy {
        text-align: center;

        h2 {
            color: $grey-200;
        }

        p {
            @include body-lg;
            margin-top: $spacing-7;
            margin-bottom: $spacing-10;
        }
    }

    .page-ctas {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $spacing-7;
        @include ie-grid(1fr, true, 3, $spacing-7);

        .cta:not(:last-of-type) {
            padding-bottom: $spacing-7;
            border-bottom: 1px solid $grey-600;
        }

        h4 {
            color: $grey-200;
            margin-bottom: $spacing-6;
        }

        .social-share {
            display: flex;

            a {
                border-radius: 50%;
                background: $grey-500;
                width: $spacing-10;
                height: $spacing-10;
                display: flex;
                align-items: center;
                justify-content: center;
                transition: background-color 0.3s ease-in-out;
                @include shadow-level(3, 1);

                &:first-of-type {
                    margin-right: $spacing-6;
                }

                svg {
                    width: 65%;
                    height: 65%;
                    color: $grey-100;
                    transition: color 0.3s ease-in-out;
                }

                &:hover,
                &:focus {
                    background-color: $grey-100;

                    svg {
                        color: $grey-700;
                    }
                }
            }
        }
    }

    footer {
        display: flex;
        align-items: center;
        flex-direction: column;
        margin-top: $spacing-7;
        border-top: 1px solid $grey-600;
        padding-top: $spacing-7;

        p {
            @include body-xs;
            color: $grey-500;
            margin-bottom: $spacing-5;

            &.quote {
                font-style: italic;
            }
        }

        .site-blurb {
            text-align: right;

            p {
                margin-bottom: 0;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .s-page-ctas {
        .row {
            @include grid-center;
        }

        .content-container {
            @include col(11);

            .page-ctas {
                grid-template-columns: auto 1fr auto;
                grid-gap: $spacing-6;
                @include ie-grid(auto 1fr auto, true, 1, $spacing-6);

                .cta:not(:last-of-type) {
                    padding-bottom: 0;
                    padding-right: $spacing-6;
                    border-bottom: none;
                    border-right: 1px solid $grey-600;
                }
            }

            footer {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: $spacing-9;

                p,
                p.quote {
                    margin: 0;
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-page-ctas {
        .content-container {
            @include col(10);

            .page-ctas {
                grid-gap: $spacing-8;
                @include ie-grid(auto 1fr auto, true, 1, $spacing-8);

                .cta:not(:last-of-type) {
                    padding-right: $spacing-8;
                }
            }
        }
    }
}
