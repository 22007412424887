// Colors
// =======================================================================
/* stylelint-disable color-no-hex */


$blue-500: #0D4078;
$blue-900: #08274A;

$wattle-500:  #FDA70F;
$wattle-900:#D78A02;

$green-100: hsl(174, 50, 96); //#008375
$green-500: hsl(174, 100, 26); //#008375
$green-900: hsl(173, 100, 16); // #005047

$color-primary-1:                          #0080D8;
$color-primary-2:                          #163C69;
$color-primary-3:                          #122438;

$color-secondary-1:                        #F9792F;
$color-secondary-2:                        #D06120;
$color-secondary-3:                        #843406;


$color-shade-1:                            #F0F0F0;
$color-shade-2:                            #DDD;
$color-shade-3:                            #9B9B9B;

$color-white:                              #FFF;

$grey-100:                                 #F7FAFC;
$grey-200:                                 #EDF2F7;
$grey-300:                                 #E2E8F0;
$grey-400:                                 #CBD5E0;
$grey-500:                                 #A0AEC0;
$grey-600:                                 #718096;
$grey-700:                                 #4A5568;
$grey-800:                                 #2D3748;
$grey-900:                                 #1A202C;

$color-black:                              #222;

$color-error:                              #F6323E;
$color-warning:                            #F7A700;
$color-success:                            #009844;

$color-facebook:                           #3B5998;
$color-google:                             #DD4B39;
$color-instagram:                          #E1306C;
$color-linkedin:                           #0077B5;
$color-pinterest:                          #CB2027;
$color-twitter:                            #55ACEE;
$color-youtube:                            #CD201F;
/* stylelint-enable color-no-hex */

// Background Themes
@mixin bg-theme-1 {
    background-color: $grey-700;

    // Set the heading / p text / anything else color here if those color needs
    // to change with the application of this bg theme
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .display-extra-extra-large,
    .display-extra-large,
    .display-large,
    .display-medium,
    .display-small,
    .display-extra-small,
    .display-extra-extra-small,
    p,
    ul,
    ol,
    ol li::before,
    blockquote,
    q {
        color: $grey-200;
    }

    ul li::before {
        background-color: $grey-200;
    }

    .btn-skin-1 {
        border-color: $grey-200;
        color: $grey-200;

        &:hover,
        &:focus {
            background-color: $grey-200;
            color: $grey-700;
        }
    }
}

.bg-theme-1 {
    @include bg-theme-1;
}

@mixin bg-theme-2 {
    background-color: $grey-200;
}

.bg-theme-2 {
    @include bg-theme-2;
}
