header {
    background-color: $color-white;

    .container {
        display: flex;
        justify-content: space-between;
        padding-top: $spacing-6;
        padding-bottom: $spacing-6;
    }

    .logo {
        display: flex;
        align-items: center;
        text-decoration: none;

        .site-title {
            @include display-large;
            margin-left: $spacing-5;
        }

        svg {
            margin-top: $spacing-1;
        }
    }

    .wrapper {
        display: flex;

        .contact {
            margin-left: $spacing-8;
        }
    }

    nav {
        display: none;

        a {
            @include display-medium;
            margin-left: $spacing-8;
            text-decoration: none;
            position: relative;
            color: $grey-800;

            @include fancy-underline;
        }
    }

    .contact {
        @include btn-skin-1;
        display: none;
    }

    .toggle-button {
        @include display-large;
        font-weight: $font-semibold;
    }
}

@media (min-width: $screen-sm) {
    header {
        nav {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }

        .contact {
            display: inline-block;
        }

        .toggle-button {
            display: none;
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
    header {
        .contact {
            @include btn-small;
        }
    }
}
