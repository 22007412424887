.s-text-icons {
    @include bg-theme-2;
    @include base-section-spacing-default('padding');

    .tile-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: $spacing-8;
        @include ie-grid(1fr, true, 3, $spacing-8);
        padding: $spacing-7 $spacing-7 $spacing-7 $spacing-5;
        background-color: $color-white;
        border-radius: $spacing-2;
        @include shadow-level(1, 3);

        .c-text-icon-tile:first-of-type .text-icon-tile-icon svg {
            width: $spacing-7 + $spacing-3;
            height: $spacing-7 + $spacing-3;
        }

        .c-text-icon-tile:nth-of-type(2) .text-icon-tile-icon svg {
            width: $spacing-8;
            height: $spacing-8;
            margin-top: -$spacing-5;
        }

        .c-text-icon-tile:last-of-type .text-icon-tile-icon svg {
            width: $spacing-8;
            height: $spacing-8;
        }
    }

    .text-icons-container {
        > *:first-child {
            margin-bottom: $spacing-8;
            color: $grey-800;
            text-align: center;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-text-icons {
        .row {
            @include grid-center;
        }

        .text-icons-container {
            @include col(11);
        }

        .tile-container {
            padding: $spacing-8 $spacing-7;

            .c-text-icon-tile:first-of-type .text-icon-tile-icon svg {
                width: $spacing-10;
                height: $spacing-10;
            }

            .c-text-icon-tile:nth-of-type(2) .text-icon-tile-icon svg {
                width: $spacing-11;
                height: $spacing-11;
                margin-top: -$spacing-7;
            }

            .c-text-icon-tile:last-of-type .text-icon-tile-icon svg {
                width: $spacing-11;
                height: $spacing-11;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-text-icons {
        .text-icons-container {
            @include col(10);
        }
    }
}
