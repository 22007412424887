input,
textarea,
select {
    &.error {
        border-color: $color-error;
    }
}

input[type="checkbox"].error + label::before,
input[type="radio"].error + label::before {
    border-color: $color-error;
}

.error-message {
    @include body-sm;
    color: $color-error;
    margin-top: $spacing-3 / 2;
}

.datepicker-wrapper.has-error .input-wrapper {
    @include bg-icon('calendar', after, $color-error);
}

.file-uploader-wrapper.has-error .btn {
    background-color: $color-error;
}

// Form Element error styling
.EditingFormValueCell .Error {
    input,
    textarea,
    select,
    input + label::before {
        border-color: $color-error;
    }

    input:focus,
    textarea:focus,
    select:focus,
    input:focus + label::before {
        border-color: $input-border-color-focus;
    }

    // Calendar Icon styling
    .date-time-picker .control-group-inline button {
        @include bg-icon('calendar', after, $color-error);
    }

    .uploader-upload label {
        background-color: $color-error;
    }

    .uploader-upload label:focus {
        &.btn-skin-1 {
            background-color: $btn-skin-1-hover-bg;
        }

        &.btn-skin-2 {
            background-color: $btn-skin-2-hover-bg;
        }
    }
}

// Select Icon styling
.select-wrapper .input-wrapper.has-error,
.select-wrapper .EditingFormValueCell > div.Error {
    @include bg-icon($select-icon, after, $color-error) {
        position: absolute;
        top: $select-icon-width / 2;
        right: $select-icon-width / 2;
        width: $select-icon-width;
        height: $select-icon-width;
        content: "";
        text-align: center;
    }
}

// Form Element error message
.EditingFormErrorLabel {
    @include body-sm;
    color: $color-error;
}
