section.s-quote {
    @include base-section-spacing-lg('padding');
    background: $color-white;
    margin-bottom: 0;

    .quote {
        text-align: center;

        h2 {
            line-height: $leading-tight;
        }

        span {
            font-weight: $font-medium;
        }

        a {
            margin-top: $spacing-10;
        }
    }
}

@media (min-width: $screen-sm) {
    .s-quote {
        .row {
            @include grid-center;
        }

        .quote {
            @include col(11);

            a {
                margin-top: $spacing-11;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .s-quote {
        .quote {
            @include col(8);
        }
    }
}
